<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Payroll</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="payrollInsert">
                <div class="form-group">
                  <label>Month/Year</label>
                  <input
                    type="month"
                    class="form-control"
                    v-model="form.monthyear"
                  />
                  <small class="text-danger" v-if="errors.year">{{
                    errors.year[0]
                  }}</small>
                  <small class="text-danger" v-if="errors.month">{{
                    errors.month[0]
                  }}</small>
                </div>
                <div
                  class="form-row align-items-center"
                  v-for="(d, index) in this.form.data"
                  :key="index"
                >
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>User</label>
                      <model-list-select
                        :list="admins"
                        v-model="d.admin_id"
                        option-value="id"
                        option-text="username"
                        placeholder="Select User"
                      >
                      </model-list-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Presence Day</label>
                      <input
                        type="number"
                        v-model="d.presence_count"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Late Presence Day</label>
                      <input
                        type="number"
                        v-model="d.late_presence_count"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Alpha Presence Day</label>
                      <input
                        type="number"
                        v-model="d.alpha_presence_count"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Overtime Day</label>
                      <input
                        type="number"
                        v-model="d.overtime_count"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Food And Beverage Cut</label>
                      <money
                        v-model="d.food_and_beverage_cut"
                        class="form-control"
                      ></money>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Debt Cut</label>
                      <money v-model="d.debt_cut" class="form-control"></money>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Additional Payroll</label>
                      <money
                        v-model="d.additional_payroll"
                        class="form-control"
                      ></money>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Bonus</label>
                      <money
                        v-model="d.bonus_other"
                        class="form-control"
                      ></money>
                    </div>
                  </div>
                  <div class="text-right">
                    <div
                      class="btn btn-danger"
                      @click="
                        deleteFind(index);
                        return false;
                      "
                      v-if="index > 0"
                    >
                      Delete
                    </div>
                    <div
                      @click="
                        addFind();
                        return false;
                      "
                      class="btn btn-success"
                    >
                      New User
                    </div>
                  </div>
                </div>
                <div class="card-footer text-right">
                  <button
                    class="btn btn-primary mr-1"
                    type="submit"
                    :disabled="isSubmitting"
                  >
                    Submit
                  </button>
                  <button class="btn btn-secondary" type="reset">Reset</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { apiGet, apiPost } from "../../services/api";
import swal from "sweetalert";

export default {
  name: "FormPayroll",
  data() {
    return {
      name: "Form Payroll",
      isChecked: "",
      form: {
        month: "",
        year: "",
        monthyear: "",
        data: [
          {
            admin_id: "",
            presence_count: 0,
            overtime_count: 0,
            late_presence_count: 0,
            alpha_presence_count: 0,
            food_and_beverage_cut: 0,
            debt_cut: 0,
            additional_payroll: 0,
            bonus_other: 0,
          },
        ],
      },
      admins: [],
      isSubmitting: false,
      errors: {},
      thr: false,
    };
  },
  mounted() {
    this.getUser();
  },
  updated() {
    this.form.month = this.getThisMonth;
    this.form.year = this.getThisYear;
  },
  methods: {
    payrollInsert() {
      this.isSubmitting = true;
      apiPost("payroll/store", this.form)
        .then(() => {
          this.$noty.success("Your Payroll has been saved!");
          this.$router.push({ name: "Payroll" });
        })
        .catch((error) => {
          if (error.response.data.code == 500) {
            swal("Error!", error.response.data.message, "error");
          }
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
    getUser() {
      apiGet("admin?payroll_info=1").then((result) => {
        this.admins = result.data.data;
      });
    },
    addFind() {
      this.form.data.push({
        admin_id: "",
        presence_count: 0,
        overtime_count: 0,
        late_presence_count: 0,
        alpha_presence_count: 0,
        food_and_beverage_cut: 0,
        debt_cut: 0,
        additional_payroll: 0,
        bonus_other: 0,
      });
    },
    deleteFind: function(index) {
      this.form.data.splice(index, 1);
    },
  },
  computed: {
    getThisYear() {
      let monthyear = this.form.monthyear.split("-")[0];
      return monthyear;
    },
    getThisMonth() {
      let monthyear = this.form.monthyear.split("-")[1];
      return monthyear;
    },
  },
};
</script>
