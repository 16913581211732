import Payroll from './Payroll.vue'
import PayrollCreate from './Create.vue'
import PayrollEdit from './Edit.vue'

export default [
    {
        path: '/payroll',
        name: 'Payroll',
        component: Payroll
    },
    {
        path: '/payroll/create',
        name: 'PayrollCreate',
        component: PayrollCreate
    },
    {
        path: '/payroll/edit/:id',
        name: 'PayrollEdit',
        component: PayrollEdit
    },
]
